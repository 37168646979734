export default {
  menu: [
    {
      label: 'home.key',
      path: '/app/home',
      icon: '$vuetify.icons.starling_home',
    },
    {
      label: 'videos.key',
      path: '/app/videos',
      icon: '$vuetify.icons.starling_videos',
    },
    {
      label: 'tools.key',
      path: '/app/tools',
      icon: '$vuetify.icons.starling_tools',
    },
    {
      label: 'you.key',
      path: '/app/profile',
      icon: '$vuetify.icons.starling_profile',
    },
  ],
};
